import { useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import AddSiteList from "./AddSiteList";
import "../../../styles/pages/Layout/ListSite.css";
import { useUserState } from "../../../system/context/UserContext";

import {
  Collapse,
  Divider,
  List,
  ListItemText,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";

import {
  Archive,
  BowlFood,
  Buildings,
  CalendarCheck,
  CaretDown,
  CaretUp,
  CreditCard,
  LinkSimple,
  ListMagnifyingGlass,
  PushPin,
  Question,
  Scroll,
  ShoppingBag,
  UserList,
  UserRectangle,
  UserSound,
} from "@phosphor-icons/react";
import { useRecoilState } from "recoil";
import { CeoAtom } from "../../../system/atom";
import { CommonRoutes } from "../../../system/types/type";

interface HeaderProps {
  open: boolean;
  setOpen: (status: boolean) => void;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
  },
}));

const mainItemStyle = {
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
  "&&.Mui-selected:hover": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
};

function ListSite({ open, setOpen }: HeaderProps) {
  const user = useUserState();
  const location = useLocation();
  const history = useHistory();
  const [, setCeoSchedulerOwner] = useRecoilState(CeoAtom);

  // 공지 list
  const [noticeOpen, setNoticeOpen] = useState(false);

  const noticeClick = () => {
    setNoticeOpen(!noticeOpen);
  };

  // 주간 메뉴 list
  const [menuOpen, setMenuOpen] = useState(false);

  const menuClick = () => {
    setMenuOpen(!menuOpen);
  };

  // 인사만사
  const [insamansaOpen, setInsamansaOpen] = useState(false);

  const insamansaClick = () => {
    setInsamansaOpen(!insamansaOpen);
  };

  // 업무 사이트 list
  const [workOpen, setWorkOpen] = useState(false);

  const workClick = () => {
    setWorkOpen(!workOpen);
  };

  // 사내 업무 사이트 list
  const [inbodyOpen, setInbodyOpen] = useState(false);

  const inbodyClick = () => {
    setInbodyOpen(!inbodyOpen);
  };

  // 대외 사이트 list
  const [siteOpen, setSiteOpen] = useState(false);

  const siteClick = () => {
    setSiteOpen(!siteOpen);
  };

  return (
    <div className="drawerOverflow">
      {open ? (
        <List style={{ cursor: "pointer" }}>
          <ListItemButton onClick={noticeClick}>
            <PushPin size={20} />
            <ListItemText primary="공지사항" sx={{ pl: 2 }} />
            {noticeOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>
          <Collapse in={noticeOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                selected={location.pathname.includes(CommonRoutes.noticeRoot)}
                sx={{ pl: 5, ...mainItemStyle }}
                component={Link}
                to="/notice/board/1"
              >
                <ListItemText primary="전사 공지" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>

              {user.parentTeam === "공통파트" || !user.parentTeam ? (
                ""
              ) : (
                <ListItemButton
                  sx={{ pl: 5, ...mainItemStyle }}
                  component={Link}
                  to="/departmentnotice/board/1"
                  selected={location.pathname.includes(
                    CommonRoutes.departmentnoticeRoot
                  )}
                >
                  <ListItemText
                    primary={user.parentTeam + " 공지"}
                    sx={{ color: "#6D6D6D" }}
                  />
                </ListItemButton>
              )}
              <ListItemButton
                sx={{ pl: 5, ...mainItemStyle }}
                component={Link}
                to="/qna/board/1"
                selected={location.pathname.includes(CommonRoutes.qnaRoot)}
              >
                <ListItemText primary="질의 응답" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={menuClick}>
            <BowlFood size={20} />

            <ListItemText primary="인바디 주간 메뉴" sx={{ pl: 2 }} />
            {menuOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>
          <Collapse in={menuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 5, ...mainItemStyle }}
                component={Link}
                to="/cheonanMenu"
                selected={location.pathname.includes(CommonRoutes.cheonanMenu)}
              >
                <ListItemText
                  primary="주간 메뉴표 (천안)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>

              {/* 사번으로 권한 체크 */}
              {["202301c", "20220914", "20190907", "20160404"].includes(
                user.employeeId
              ) ? (
                <ListItemButton
                  sx={{ pl: 5, ...mainItemStyle }}
                  component={Link}
                  to="/admin/Menu"
                  selected={location.pathname.includes(CommonRoutes.menuAdmin)}
                >
                  <ListItemText
                    primary="인바디 주간 메뉴 설정"
                    sx={{ color: "#6D6D6D" }}
                  />
                </ListItemButton>
              ) : (
                ""
              )}
            </List>
          </Collapse>

          <ListItemButton
            key={"그룹웨어"}
            onClick={() => {
              window.open("https://groupware.weareinbody.com/");
            }}
          >
            <Buildings size={20} />
            <ListItemText primary="그룹웨어" sx={{ pl: 2 }} />
          </ListItemButton>

          <ListItemButton
            key={"QnA"}
            onClick={() => {
              window.open("https://inbodykr.sharepoint.com/sites/InBodyQNA");
            }}
          >
            <Question size={20} />
            <ListItemText primary="회사생활 가이드" sx={{ pl: 2 }} />
          </ListItemButton>

          <ListItemButton
            key={"인바디 예약 시스템"}
            onClick={() => {
              window.open("https://inserve.weareinbody.com/");
            }}
          >
            <CalendarCheck size={20} />
            <ListItemText primary="인바디 예약 시스템" sx={{ pl: 2 }} />
          </ListItemButton>

          <LightTooltip title="회사코드(P208)" placement="right">
            <ListItemButton
              key={"ipayview"}
              onClick={() => {
                window.open("https://www.ipayview.com/index.asp");
              }}
            >
              <Scroll size={20} />
              <ListItemText primary="급여(iPayView)" sx={{ pl: 2 }} />
            </ListItemButton>
          </LightTooltip>

          <ListItemButton
            key={"법인카드"}
            onClick={() => {
              window.open("https://www.bizplay.co.kr/bizpr_main.act");
            }}
          >
            <CreditCard size={20} />
            <ListItemText primary="법인카드" sx={{ pl: 2 }} />
          </ListItemButton>
          <ListItemButton onClick={insamansaClick}>
            <UserList size={20} />
            <ListItemText primary="인사 업무 사이트" sx={{ pl: 2 }} />
            {insamansaOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>

          <Collapse in={insamansaOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://hr.workup.plus/cloudhr/1.0/login");
                }}
              >
                <ListItemText primary="WorkUp" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://insamansa2.weareinbody.com/");
                }}
              >
                <ListItemText primary="인사만사" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open(
                    "https://insamansa2.weareinbody.com/today-attendance"
                  );
                }}
              >
                <ListItemText primary="오늘의 근태" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://insamansa2.weareinbody.com/evidence");
                }}
              >
                <ListItemText
                  primary="증빙서류 발급"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("http://www.dcorising.co.kr/card/?company_no=31");
                }}
              >
                <ListItemText primary="명함 신청" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton
            key={"VoE 사이트"}
            onClick={() => {
              window.open("https://voe.weareinbody.com");
            }}
          >
            <UserSound size={20} />
            <ListItemText primary="VoE 사이트" sx={{ pl: 2 }} />
          </ListItemButton>
          <ListItemButton onClick={workClick}>
            <Archive size={20} />
            <ListItemText primary="과제 업무 사이트" sx={{ pl: 2 }} />
            {workOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>
          <Collapse in={workOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://works.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="과제 업무 사이트"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open(
                    "https://work.weareinbody.com/Work/WorkList.aspx"
                  );
                }}
              >
                <ListItemText
                  primary="(구) 과제 업무 사이트"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={inbodyClick}>
            <LinkSimple size={20} />
            <ListItemText primary="인바디 업무 사이트" sx={{ pl: 2 }} />
            {inbodyOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>
          <Collapse in={inbodyOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open(
                    "https://inbody.operations.dynamics.com/?cmp=IHQ&mi=DefaultDashboard"
                  );
                }}
              >
                <ListItemText
                  primary="Dynamics365(ERP)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://mar.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="요청접수(MAR)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://lib.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="이미지풀(InBody Library)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open(
                    "https://inbodykr.sharepoint.com/sites/certification2"
                  );
                }}
              >
                <ListItemText primary="제품인증" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://inbody-backoffice.azurewebsites.net/");
                }}
              >
                <ListItemText primary="BackOffice" sx={{ color: "#6D6D6D" }} />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://forecasts.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="포캐스트(Forecast)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://order.weareinbody.com/Main.aspx");
                }}
              >
                <ListItemText
                  primary="제품발주(ORDER)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://pdm.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="제품관리(PDM)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://crm.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="고객관리(CRM)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://dhr.weareinbody.com/");
                }}
              >
                <ListItemText
                  primary="제품생산(DHR)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={siteClick}>
            <ListMagnifyingGlass size={20} />
            <ListItemText primary="인바디 대외 사이트" sx={{ pl: 2 }} />
            {siteOpen ? <CaretUp /> : <CaretDown />}
          </ListItemButton>
          <Collapse in={siteOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://www.inbody.co.kr/");
                }}
              >
                <ListItemText
                  primary="인바디 홈페이지"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://www.inbodyrecruit.com/");
                }}
              >
                <ListItemText
                  primary="InBody 채용 홈페이지"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://store.inbody.com/");
                }}
              >
                <ListItemText
                  primary="InBody 자사몰(국내)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://inbodyshop.com/");
                }}
              >
                <ListItemText
                  primary="InBody 자사몰(해외)"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://inbodyservice.com/");
                }}
              >
                <ListItemText
                  primary="InBody Service"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open(
                    "https://m.blog.naver.com/PostList.nhn?blogId=inbody0515"
                  );
                }}
              >
                <ListItemText
                  primary="인바디 네이버 블로그"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://brand.naver.com/inbody");
                }}
              >
                <ListItemText
                  primary="인바디 네이버 스토어"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://www.facebook.com/myinbody");
                }}
              >
                <ListItemText
                  primary="인바디 페이스북"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={() => {
                  window.open("https://www.instagram.com/inbody_official/");
                }}
              >
                <ListItemText
                  primary="인바디 인스타그램"
                  sx={{ color: "#6D6D6D" }}
                />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            key={"대표이사 일정표"}
            onClick={() => {
              history.push({ pathname: "/ceoScheduler" });
            }}
            sx={{ ...mainItemStyle }}
            selected={location.pathname.includes(CommonRoutes.ceoschedule)}
          >
            <UserRectangle size={20} />
            <ListItemText primary="대표이사 일정표" sx={{ pl: 2 }} />
          </ListItemButton>

          <Divider style={{ borderBottom: "dashed 1px #6D6D6D" }} />
        </List>
      ) : (
        <></>
      )}
      <AddSiteList open={open} />
    </div>
  );
}

export default ListSite;
