import React from "react";
import {
  BoxTitle,
  WorkingTimeAlign,
  WorkingTimeContent,
  WorkingTimeTitle,
} from "../../../styles/Components/Main/MainPortal";
import ProgressTemplate from "./ProgressTemplate";
import { Divider, Grid, styled } from "@mui/material";
import { NoticeButtonRed } from "../../../styles/Common/CommonComponent";
import { GRAYPALEBORDER } from "../../../styles/Color";
import { useUserState } from "../../../system/context/UserContext";

interface OwTimePortletProps {
  searchDate: Date;
  owTime: number;
}

export default function OwTimePortlet({
  searchDate = new Date(),
  owTime,
}: OwTimePortletProps) {
  const user = useUserState();
  // 부서장(팀장, 파트장, 그룹장), 인사파트 권한 체크
  const isOwTimeManager =
    "실장그룹장/파트장/팀장".includes(user.jobTitle) || user.parentTeamId === "PT01";

  let searchDay = searchDate.getDay();
  if (searchDay === 0 || searchDay === 6) searchDay = 5;
  if (searchDay === 0 || searchDay === 6) searchDay = 5;

  let inputOwTime = owTime;
  if (isNaN(owTime)) inputOwTime = 0;
  if (isNaN(owTime)) inputOwTime = 0;

  const AVAILABLE_WORKING_HOURS = 2.4;
  const maximumWorkingHours = AVAILABLE_WORKING_HOURS * 5;

  const ableToWorkHours = AVAILABLE_WORKING_HOURS * searchDay;
  const overWorkHours = Math.round((inputOwTime * 10) / 60) / 10;

  const weekPercentage = (overWorkHours / maximumWorkingHours) * 100;
  const dayPercentage = (overWorkHours / ableToWorkHours) * 100;
  const VerticalDivider = styled("div")<{ $searchDay: number }>(
    ({ $searchDay }) => ({
      position: "absolute",
      width: 5,
      height: "50%",
      display: "flex",
      bottom: "0px",
      left: `calc(100% * ${$searchDay} / 5)`,
      backgroundColor: "#7a2f2f",
      opacity: 1,
    })
  );

  const OwTimeContent = styled("div")(({ theme }) => ({
    position: "relative",
  }));

  // BackOffice 야근관리 페이지 새탭에서 실행
  const handleClickOwTimePageLink = () => {
    window.open(
      "https://inbody-backoffice.azurewebsites.net/employee-overtime"
    );
  };

  return (
    <>
      <Grid container spacing={0} mt={"10px"}>
        <Grid item xs={4}>
          <BoxTitle variant="h6">누적 초과 근무 시간</BoxTitle>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            borderBottom: `1px solid ${GRAYPALEBORDER}`,
            textAlign: "left",
          }}
        >
          {isOwTimeManager ? (
            <NoticeButtonRed
              onClick={handleClickOwTimePageLink}
              style={{ width: "220px", marginLeft: "0px", cursor: "pointer" }}
            >
              부서원 초과 근무 현황 보기
            </NoticeButtonRed>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={7}>
          <OwTimeContent>
            <ProgressTemplate
              overWorkHours={overWorkHours}
              maximumWorkingHours={maximumWorkingHours}
              weekPercentage={weekPercentage}
              dayPercentage={dayPercentage}
            />
            <VerticalDivider $searchDay={searchDay} /> {/* orientation="vertical" */}
          </OwTimeContent>
        </Grid>

        <Grid item xs={5} pl={"30px"}>
          <WorkingTimeAlign style={{ marginTop: "20px" }}>
            <WorkingTimeTitle>기준 대비 초과 근무 가능 시간</WorkingTimeTitle>
            <WorkingTimeContent>{`${(12 - overWorkHours).toFixed(
              1
            )} 시간`}</WorkingTimeContent>
          </WorkingTimeAlign>
        </Grid>
      </Grid>
    </>
  );
}
