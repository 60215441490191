import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import axios from "axios";
import "weather-icons/css/weather-icons.css";
import "../../../../src/styles/Components/Main/Portlet.css";

import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import TitleBox from "../../../components/Common/TitleBox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ErrorHandler, WeatherSiteApi } from "../../../system/ApiService";
import { useUserState } from "../../../system/context/UserContext";
import { WeatherSite } from "../../../system/types/interface";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
} from "../../../styles/Common/CommonComponent";
import AlertModal from "../../Common/AlertModal";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 500,
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function WeatherPortlet() {
  const user = useUserState();
  // router 이동시 메모리 lack 에러
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    return () => setLoading(false);
  }, []);

  // 위치 설정 모달
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [siteX, setSiteX] = useState(61);
  const [siteY, setSiteY] = useState(126);
  const [siteAddress, setSiteAddress] = useState("논현동");
  const [siteData, setSiteData] = useState<WeatherSite[]>([]);
  const [selectAddress, setSelectAddress] = useState("");
  const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAddress((event.target as HTMLInputElement).value);
  };
  const onSaveAddress = () => {
    var x = 0;
    var y = 0;
    if (selectAddress === "천안시 서북구 입장면") {
      x = 64;
      y = 113;
    } else if (selectAddress === "논현동") {
      x = 61;
      y = 126;
    } else {
      x = 61;
      y = 125;
    }
    setSiteAddress(siteAddress);
    if (siteData) {
      const newData: WeatherSite = {
        employeeId: user.employeeId,
        siteX: x,
        siteY: y,
        siteAddress: selectAddress,
      };
      WeatherSiteApi.UpdateWeatherSite(newData)
        .then(() => {
          refreshWeatherSite();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      const newData: WeatherSite = {
        employeeId: user.employeeId,
        siteX: x,
        siteY: y,
        siteAddress: selectAddress,
      };
      WeatherSiteApi.InsertWeatherSite(newData)
        .then(() => {
          refreshWeatherSite();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
    setOpen(false);
  };

  function refreshWeatherSite() {
    WeatherSiteApi.GetWeatherSite(user.employeeId)
      .then((res) => {
        setSiteData(res.data);
        setSiteX(res.data.siteX);
        setSiteY(res.data.siteY);
        setSiteAddress(res.data.siteAddress);
        setSelectAddress(res.data.siteAddress);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshWeatherSite();
  }, []);

  // 기상청
  const [forecastData, setForecastData] = useState([]);
  const data_api_key =
    "9R103KDTnKu2apR3vh4Bx0X9kCN%2FI%2BI0eFs8pvVZAOKfwuYm9g5IxjeLNqFjSV5vA0UtfGJj5V%2Fgh3RS2DluAA%3D%3D";
  const api_go =
    "http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getVilageFcst";
  const [temp_max, setTemp_max] = useState(0);
  const [temp_min, setTemp_min] = useState(0);
  const [temp_max_tmr, setTemp_max_tmr] = useState(0);
  const [temp_min_tmr, setTemp_min_tmr] = useState(0);
  const [temp_max_dat, setTemp_max_dat] = useState(0);
  const [temp_min_dat, setTemp_min_dat] = useState(0);
  const [skyCondition, setSkyCondition] = useState(0);
  const [rainCondition, setRainCondition] = useState(0);
  const [temp_now, setTemp_now] = useState(0);
  const [rain, setRain] = useState("강수없음");

  // 오늘 날짜
  let today = new Date();
  let year = today.getFullYear();
  let tempMonth = today.getMonth() + 1;
  let month = tempMonth < 10 ? "0" + tempMonth : tempMonth;
  let tempDate = today.getDate();
  let date = tempDate < 10 ? "0" + tempDate : tempDate;
  let time = today.getHours();

  let base_today = year + "" + month + "" + date;
  let base_time = time + "00";

  // 어제 날짜
  let nowDate = new Date();
  let yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
  nowDate.setTime(yesterDate);

  let yesterYear = nowDate.getFullYear();
  let tempYesterMonth = nowDate.getMonth() + 1;
  let tempYesterDay = nowDate.getDate();
  let yesterMonth =
    tempYesterMonth < 10 ? "0" + tempYesterMonth : tempYesterMonth;
  let yesterDay = tempYesterDay < 10 ? "0" + tempYesterDay : tempYesterDay;

  // 내일 날짜
  let nowDate2 = new Date();
  let tmrDate = nowDate2.getTime() + 1 * 24 * 60 * 60 * 1000;
  nowDate2.setTime(tmrDate);

  let tmrYear = nowDate2.getFullYear();
  let tempTmrMonth = nowDate2.getMonth() + 1;
  let tempTmrDay = nowDate2.getDate();
  let tmrMonth = tempTmrMonth < 10 ? "0" + tempTmrMonth : tempTmrMonth;
  let tmrDay = tempTmrDay < 10 ? "0" + tempTmrDay : tempTmrDay;

  // 내일모레 날짜
  let nowDate3 = new Date();
  let twoafterDate = nowDate3.getTime() + 2 * 24 * 60 * 60 * 1000;
  nowDate3.setTime(twoafterDate);

  let twoafterYear = nowDate3.getFullYear();
  let tempTwoAfterMonth = nowDate3.getMonth() + 1;
  let tempTwoAfterDay = nowDate3.getDate();
  let twoafterMonth =
    tempTwoAfterMonth < 10 ? "0" + tempTwoAfterMonth : tempTwoAfterMonth;
  let twoafterDay =
    tempTwoAfterDay < 10 ? "0" + tempTwoAfterDay : tempTwoAfterDay;

  // 날
  let base_yesterday = yesterYear + "" + yesterMonth + "" + yesterDay;
  let base_tmrday = tmrYear + "" + tmrMonth + "" + tmrDay;

  // 요일(내일, 내일모레)
  var week = new Array(
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일"
  );
  var dayOftheWeek = twoafterYear + "-" + twoafterMonth + "-" + twoafterDay;
  var twoafterDOW = new Date(`${dayOftheWeek}`).getDay();
  var DOWdayOftheWeek = week[twoafterDOW];

  // 초단기예보 (현재 온도)

  let temp_before_time = time - 1;
  let before_time =
    temp_before_time < 10
      ? "0" + temp_before_time + "00"
      : temp_before_time + "00";

  let temp_after_time = time + 1;
  let after_time =
    temp_after_time < 10
      ? "0" + temp_after_time + "00"
      : temp_after_time + "00";
  const api_nowtemp =
    "http://apis.data.go.kr/1360000/VilageFcstInfoService_2.0/getUltraSrtFcst";

  useEffect(() => {
    let mounted = true;

    const fetchWeatherData = async () => {
      try {
        if (time > 0) {
          const response = await axios.get(
            `${api_nowtemp}?serviceKey=${data_api_key}&numOfRows=60&pageNo=1&dataType=JSON&base_date=${base_today}&base_time=${before_time}&nx=${siteX}&ny=${siteY}`
          );

          // API 응답 구조 확인
          if (!response?.data?.response?.body?.items?.item) {
            console.error("Invalid API response structure:", response);
            return;
          }

          let JsonNow = JSON.stringify(response.data.response.body.items.item);
          const Jsonnowtmp = JSON.parse(JsonNow).filter((element: any) => {
            return (
              element.fcstTime === `${after_time}` && element.category === "T1H"
            );
          });

          if (Jsonnowtmp.length > 0) {
            setTemp_now(Jsonnowtmp[0].fcstValue);
          }

          const Jsonnowrain = JSON.parse(JsonNow).filter((element: any) => {
            return (
              element.fcstTime === `${after_time}` && element.category === "RN1"
            );
          });

          if (Jsonnowrain.length > 0) {
            setRain(Jsonnowrain[0].fcstValue);
          }

          const Jsonnowsky = JSON.parse(JsonNow).filter((element: any) => {
            return (
              element.fcstTime === `${after_time}` && element.category === "SKY"
            );
          });

          if (Jsonnowsky.length > 0) {
            setSkyCondition(Jsonnowsky[0].fcstValue);
          }

          const Jsonnowraincondition = JSON.parse(JsonNow).filter(
            (element: any) => {
              return (
                element.fcstTime === `${after_time}` &&
                element.category === "PTY"
              );
            }
          );

          if (Jsonnowraincondition.length > 0) {
            setRainCondition(Jsonnowraincondition[0].fcstValue);
          }
        } else {
          // 비슷한 에러 처리를 다른 API 호출에도 적용
          const response = await axios.get(
            `${api_nowtemp}?serviceKey=${data_api_key}&numOfRows=60&pageNo=1&dataType=JSON&base_date=${base_yesterday}&base_time=2300&nx=${siteX}&ny=${siteY}`
          );

          if (!response?.data?.response?.body?.items?.item) {
            console.error("Invalid API response structure:", response);
            return;
          }

          // 나머지 코드는 동일한 방식으로 에러 처리 추가
          // ...
        }
      } catch (error) {
        console.error("Error fetching weather data:", error);
        // 에러 상태 처리 - 사용자에게 표시할 수 있음
      }
    };

    if (mounted) {
      fetchWeatherData();
    }

    return () => {
      mounted = false;
    };
  }, [siteAddress]);

  // 내일, 모레 데이터
  const [poptmrAM, setPoptmrAM] = useState(0);
  const [poptmrPM, setPoptmrPM] = useState(0);
  const [poptdaAM, setPoptdaAM] = useState(0);
  const [poptdaPM, setPoptdaPM] = useState(0);

  const [skytmrAM, setSkytmrAM] = useState(0);
  const [skytmrPM, setSkytmrPM] = useState(0);
  const [skytdaAM, setSkytdaAM] = useState(0);
  const [skytdaPM, setSkytdaPM] = useState(0);

  const [ptytmrAM, setPtytmrAM] = useState(0);
  const [ptytmrPM, setPtytmrPM] = useState(0);
  const [ptytdaAM, setPtytdaAM] = useState(0);
  const [ptytdaPM, setPtytdaPM] = useState(0);

  // 단기 예보(오늘 최저, 최고 & 내일, 모레 날씨 정보)
  useEffect(() => {
    let mounted = true;
    if (time > 2) {
      axios
        .get(
          `${api_go}?serviceKey=${data_api_key}&pageNo=1&numOfRows=846&dataType=JSON&base_date=${base_today}&base_time=0200&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          setForecastData(res.data.response.body.items.item);
          let JsonMaxMin = JSON.stringify(res.data.response.body.items.item);
          const Json_tempMin = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMN";
          });
          const Json_tempMax = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMX";
          });
          const Json_pop = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "POP";
          });
          const poptmrAM = Json_pop.slice(21, 33).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptmrPM = Json_pop.slice(33, 45).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaAM = Json_pop.slice(45, 57).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaPM = Json_pop.slice(57, 70).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const Json_skyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "0700";
          });
          const Json_skyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "1800";
          });
          const Json_ptyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "0700";
          });
          const Json_ptyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "1800";
          });
          setSkytmrAM(Json_skyAM[1].fcstValue);
          setSkytdaAM(Json_skyAM[2].fcstValue);
          setSkytmrPM(Json_skyPM[1].fcstValue);
          setSkytdaPM(Json_skyPM[2].fcstValue);
          setPtytmrAM(Json_ptyAM[1].fcstValue);
          setPtytdaAM(Json_ptyAM[2].fcstValue);
          setPtytmrPM(Json_ptyPM[1].fcstValue);
          setPtytdaPM(Json_ptyPM[2].fcstValue);
          setPoptmrAM(poptmrAM.fcstValue);
          setPoptmrPM(poptmrPM.fcstValue);
          setPoptdaAM(poptdaAM.fcstValue);
          setPoptdaPM(poptdaPM.fcstValue);
          setTemp_min(Json_tempMin[0].fcstValue);
          setTemp_max(Json_tempMax[0].fcstValue);
          setTemp_min_tmr(Json_tempMin[1].fcstValue);
          setTemp_max_tmr(Json_tempMax[1].fcstValue);
          setTemp_min_dat(Json_tempMin[2].fcstValue);
          setTemp_max_dat(Json_tempMax[2].fcstValue);
        });
    } else {
      axios
        .get(
          `${api_go}?serviceKey=${data_api_key}&pageNo=1&numOfRows=737&dataType=JSON&base_date=${base_yesterday}&base_time=2300&nx=${siteX}&ny=${siteY}`
        )
        .then((res) => {
          let JsonMaxMin = JSON.stringify(res.data.response.body.items.item);
          const Json_tempMin = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMN";
          });
          const Json_tempMax = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "TMX";
          });
          const Json_pop = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "POP";
          });
          const poptmrAM = Json_pop.slice(24, 36).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptmrPM = Json_pop.slice(36, 48).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaAM = Json_pop.slice(48, 60).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const poptdaPM = Json_pop.slice(63, 73).reduce(function (
            prev: any,
            current: any
          ) {
            return prev.fcstValue > current.fcstValue ? prev : current;
          });
          const Json_skyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "0700";
          });
          const Json_skyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "SKY" && element.fcstTime === "1800";
          });
          const Json_ptyAM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "0700";
          });
          const Json_ptyPM = JSON.parse(JsonMaxMin).filter(function (
            element: any
          ) {
            return element.category === "PTY" && element.fcstTime === "1800";
          });
          setSkytmrAM(Json_skyAM[1].fcstValue);
          setSkytdaAM(Json_skyAM[2].fcstValue);
          setSkytmrPM(Json_skyPM[1].fcstValue);
          setSkytdaPM(Json_skyPM[2].fcstValue);
          setPtytmrAM(Json_ptyAM[1].fcstValue);
          setPtytdaAM(Json_ptyAM[2].fcstValue);
          setPtytmrPM(Json_ptyPM[1].fcstValue);
          setPtytdaPM(Json_ptyPM[2].fcstValue);
          setPoptmrAM(poptmrAM.fcstValue);
          setPoptmrPM(poptmrPM.fcstValue);
          setPoptdaAM(poptdaAM.fcstValue);
          setPoptdaPM(poptdaPM.fcstValue);
          setTemp_min(Json_tempMin[0].fcstValue);
          setTemp_max(Json_tempMax[0].fcstValue);
          setTemp_min_tmr(Json_tempMin[1].fcstValue);
          setTemp_max_tmr(Json_tempMax[1].fcstValue);
          setTemp_min_dat(Json_tempMin[2].fcstValue);
          setTemp_max_dat(Json_tempMax[2].fcstValue);
        });
    }
    return () => {
      mounted = false;
    };
  }, [siteAddress]);

  // 날씨 아이콘 & description
  const [icons, setIcons] = useState("");
  const [description, setDescription] = useState("");
  const [tmrAMIcons, setTmrAMIcons] = useState("");
  const [tmrPMIcons, setTmrPMIcons] = useState("");
  const [tdaAMIcons, setTdaAMIcons] = useState("");
  const [tdaPMIcons, setTdaPMIcons] = useState("");
  useEffect(() => {
    let mounted = true;
    if (rainCondition) {
      if (Number(rainCondition) == 3) {
        setIcons("/images/snowy.png");
      } else if (Number(rainCondition) === 7) {
        setIcons("/images/snowy.png");
      } else if (Number(rainCondition) === 2) {
        setIcons("/images/snowing.png");
      } else if (Number(rainCondition) === 6) {
        setIcons("/images/snowing.png");
      } else if (Number(rainCondition) === 5) {
        setIcons("/images/shower.png");
      } else if (Number(rainCondition) === 1) {
        setIcons("/images/rainy.png");
      } else {
        if (Number(skyCondition) === 1) {
          setIcons("/images/sunny.png");
        } else if (Number(skyCondition) === 3) {
          setIcons("/images/clouds.png");
        } else {
          setIcons("/images/cloudy.png");
        }
      }
    }
    if (rainCondition) {
      if (Number(rainCondition) === 1) {
        setDescription("비");
      } else if (Number(rainCondition) === 2) {
        setDescription("비/눈");
      } else if (Number(rainCondition) === 3) {
        setDescription("눈");
      } else if (Number(rainCondition) === 5) {
        setDescription("빗방울");
      } else if (Number(rainCondition) === 6) {
        setDescription("빗방울눈날림");
      } else if (Number(rainCondition) === 7) {
        setDescription("눈날림");
      } else {
        if (Number(skyCondition) === 1) {
          setDescription("맑음");
        } else if (Number(skyCondition) === 3) {
          setDescription("구름많음");
        } else {
          setDescription("흐림");
        }
      }
    }
    if (ptytmrAM) {
      if (Number(ptytmrAM) === 3) {
        setTmrAMIcons("/images/snowy.png");
      } else if (Number(ptytmrAM) === 2) {
        setTmrAMIcons("/images/snowing.png");
      } else if (Number(ptytmrAM) === 1) {
        setTmrAMIcons("/images/rainy.png");
      } else if (Number(ptytmrAM) === 4) {
        setTmrAMIcons("/images/shower.png");
      } else {
        if (Number(skytmrAM) === 1) {
          setTmrAMIcons("/images/sunny.png");
        } else if (Number(skytmrAM) === 3) {
          setTmrAMIcons("/images/clouds.png");
        } else {
          setTmrAMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytmrPM) {
      if (Number(ptytmrPM) === 3) {
        setTmrPMIcons("/images/snowy.png");
      } else if (Number(ptytmrPM) === 2) {
        setTmrPMIcons("/images/snowing.png");
      } else if (Number(ptytmrPM) === 1) {
        setTmrPMIcons("/images/rainy.png");
      } else if (Number(ptytmrPM) === 4) {
        setTmrPMIcons("/images/shower.png");
      } else {
        if (Number(skytmrPM) === 1) {
          setTmrPMIcons("/images/sunny.png");
        } else if (Number(skytmrPM) === 3) {
          setTmrPMIcons("/images/clouds.png");
        } else {
          setTmrPMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytdaAM) {
      if (Number(ptytdaAM) === 3) {
        setTdaAMIcons("/images/snowy.png");
      } else if (Number(ptytdaAM) === 2) {
        setTdaAMIcons("/images/snowing.png");
      } else if (Number(ptytdaAM) === 1) {
        setTdaAMIcons("/images/rainy.png");
      } else if (Number(ptytdaAM) === 4) {
        setTdaAMIcons("/images/shower.png");
      } else {
        if (Number(skytdaAM) === 1) {
          setTdaAMIcons("/images/sunny.png");
        } else if (Number(skytdaAM) === 3) {
          setTdaAMIcons("/images/clouds.png");
        } else {
          setTdaAMIcons("/images/cloudy.png");
        }
      }
    }
    if (ptytdaPM) {
      if (Number(ptytdaPM) === 3) {
        setTdaPMIcons("/images/snowy.png");
      } else if (Number(ptytdaPM) === 2) {
        setTdaPMIcons("/images/snowing.png");
      } else if (Number(ptytdaPM) === 1) {
        setTdaPMIcons("/images/rainy.png");
      } else if (Number(ptytdaPM) === 4) {
        setTdaPMIcons("/images/shower.png");
      } else {
        if (Number(skytdaPM) === 1) {
          setTdaPMIcons("/images/sunny.png");
        } else if (Number(skytdaPM) === 3) {
          setTdaPMIcons("/images/clouds.png");
        } else {
          setTdaPMIcons("/images/cloudy.png");
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [rainCondition, ptytmrAM, ptytdaAM, ptytdaAM, ptytdaPM]);

  return (
    <div className="PortletLayout">
      <Typography variant="h5" style={{ padding: "5px 0px 5px 0px" }}>
        날씨 예보
        <Typography
          variant="subtitle2"
          component="span"
          onClick={() => {
            window.open(
              "https://www.weather.go.kr/w/weather/forecast/short-term.do"
            );
          }}
          style={{
            cursor: "pointer",
            paddingTop: "10px",
            paddingRight: "5px",
            color: "#81848B",
            float: "right",
            display: "inline-block",
          }}
        >
          [전체보기]
        </Typography>
        <Typography
          variant="caption"
          component="span"
          style={{ color: "#81848B", margin: "0px 5px" }}
        >
          {time === 23
            ? `(${tmrMonth}/${tmrDay} 0시 기준)`
            : `(${time + 1}시 기준)`}
        </Typography>
        <HtmlTooltip
          placement="right"
          title={
            <React.Fragment>
              <Typography color="inherit">날씨 예보 정보</Typography>
              {
                "날씨 예보는 대한민국 기상청에서 국내 날씨 예보 정보를 받아 제공됩니다."
              }
              <br />
              <br />
              <b>{"상단부 제공 내용"}</b>
              <br />
              {
                " 현재 이후 시간 중 가장 가까운 시간대의 날씨, 기온, 1시간 동안의 강수량 / 하루 중 최고, 최저 기온"
              }
              <br />
              <b>{"하단부 제공 내용"}</b>
              <br />
              {
                " 오전, 오후 출퇴근 시간대의 날씨(아이콘)와 강수량(%) / 하루 중 최고, 최저 기온"
              }
            </React.Fragment>
          }
        >
          <InfoIcon color="disabled" fontSize="small" />
        </HtmlTooltip>
      </Typography>
      <div className="portlet_content">
        <Typography
          variant="subtitle2"
          align="right"
          component="div"
          style={{ marginRight: "10px", color: "#81848B" }}
        >
          위치 : {siteAddress ? siteAddress : "논현동"}
          <Tooltip title="위치 설정" placement="right">
            <SettingsIcon
              style={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={handleOpen}
            />
          </Tooltip>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="modalTitle">
                <TitleBox title="날씨 예보 위치 설정" />
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  sx={{ fontSize: 40 }}
                />
              </div>
              <div style={{ padding: "30px 10px 30px 10px" }}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{ marginBottom: "10px", color: "#81848B" }}
                  >
                    회사 위치
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={siteAddress}
                    name="radio-buttons-group"
                    onChange={onChangeAddress}
                  >
                    <FormControlLabel
                      value="논현동"
                      control={<Radio />}
                      label="(주)인바디 본사 | 서울시 강남구 언주로 625 인바디빌딩"
                      style={{ margin: "10px 0px" }}
                    />
                    <FormControlLabel
                      value="개포동"
                      control={<Radio />}
                      label="(주)인바디 벤처센터 | 서울시 강남구 논현로 2길 54 인바디벤처센터"
                      style={{ margin: "10px 0px" }}
                    />
                    <FormControlLabel
                      value="양재2동"
                      control={<Radio />}
                      label="(주)인바디 양재센터 | 서울특별시 서초구 동산로 1 5층 인바디양재센터"
                      style={{ margin: "10px 0px" }}
                    />
                    <FormControlLabel
                      value="천안시 서북구 입장면"
                      control={<Radio />}
                      label="㈜인바디 공장 | 충청남도 천안시 서북구 입장면"
                      style={{ margin: "10px 0px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="tab_bt_wrap">
                <CustomButtonGrey onClick={handleClose}>취소</CustomButtonGrey>
                <CustomButtonBlackSpace onClick={onSaveAddress}>
                  저장
                </CustomButtonBlackSpace>
              </div>
            </Box>
          </Modal>
        </Typography>
        <div style={{ textAlign: "center", margin: "10px 0px" }}>
          <div
            style={
              window.innerWidth < 600
                ? { verticalAlign: "top" }
                : {
                    display: "inline-block",
                    verticalAlign: "top",
                    marginRight: "30px",
                  }
            }
          >
            {icons ? (
              <img
                src={icons}
                alt="weather"
                style={{ width: "120px", height: "120px" }}
              ></img>
            ) : (
              <img
                src="/images/cloudy.png"
                alt="weather"
                style={{ width: "120px", height: "120px" }}
              ></img>
            )}

            <Tooltip
              title={description ? description : "흐림"}
              placement="right"
            >
              <IconButton>
                <InfoIcon color="disabled" fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
          <Typography
            variant="h6"
            style={{
              display: "inline-block",
              color: "#4B4F5A",
              marginLeft: "30px",
              textAlign: "left",
            }}
          >
            현재 기온
            <br />
            최고 기온
            <br />
            최저 기온
            <br />
            강수량
            <Typography
              variant="caption"
              component="span"
              style={{ color: "#81848B" }}
            >
              (1시간)
            </Typography>
          </Typography>

          <Typography
            variant="h6"
            style={{
              display: "inline-block",
              marginLeft: "30px",
              color: "#4B4F5A",
              textAlign: "right",
            }}
          >
            {temp_now ? temp_now : "-"}°C <br />
            {temp_max ? temp_max : "-"}°C
            <br />
            {temp_min ? temp_min : "-"}°C
            <br />
            {rain === "강수없음" ? "없음" : `${rain}`}
          </Typography>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="tmrforecast">
            <div className="tmrforecastContent ">
              <Typography variant="subtitle2" component="div" align="center">
                내일
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {tmrMonth}.{tmrDay}.
              </Typography>
            </div>
            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#81848B" }}
              >
                오전
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {poptmrAM}%
              </Typography>
            </div>
            {tmrAMIcons ? (
              <div className="forecastImg">
                <img
                  src={tmrAMIcons}
                  alt="내일 오전 날씨"
                  className="imgSize"
                ></img>
              </div>
            ) : (
              ""
            )}

            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#81848B" }}
              >
                오후
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {poptmrPM}%
              </Typography>
            </div>
            {tmrPMIcons ? (
              <div className="forecastImg">
                <img
                  src={tmrPMIcons}
                  alt="내일 오후 날씨"
                  className="imgSize"
                ></img>
              </div>
            ) : (
              ""
            )}

            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="span"
                align="center"
                style={{ color: "#1E66D9" }}
              >
                {Math.floor(temp_min_tmr)}°C
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#CC1F3B" }}
              >
                {" "}
                {Math.floor(temp_max_tmr)}°C
              </Typography>
            </div>
          </div>

          <div className="tmrforecast">
            <div className="tmrforecastContent ">
              <Typography variant="subtitle2" component="div" align="center">
                {DOWdayOftheWeek}
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {twoafterMonth}.{twoafterDay}.
              </Typography>
            </div>
            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#81848B" }}
              >
                오전
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {poptdaAM}%
              </Typography>
            </div>
            {tdaAMIcons ? (
              <div className="forecastImg">
                <img
                  src={tdaAMIcons}
                  alt="모레 오전 날씨"
                  className="imgSize"
                ></img>
              </div>
            ) : (
              ""
            )}

            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#81848B" }}
              >
                오후
              </Typography>
              <Typography variant="subtitle2" component="div" align="center">
                {poptdaPM}%
              </Typography>
            </div>
            {tdaPMIcons ? (
              <div className="forecastImg">
                <img
                  src={tdaPMIcons}
                  alt="모레 오후 날씨"
                  className="imgSize"
                ></img>
              </div>
            ) : (
              ""
            )}

            <div className="tmrforecastContent ">
              <Typography
                variant="subtitle2"
                component="span"
                align="center"
                style={{ color: "#1E66D9" }}
              >
                {Math.floor(temp_min_dat)}°C
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                align="center"
                style={{ color: "#CC1F3B" }}
              >
                {" "}
                {Math.floor(temp_max_dat)}°C
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeatherPortlet;
