import React, { useCallback, useEffect, useState } from "react";
import {
  PortletLocation,
  PortletSetting,
  PortletLocationArray,
} from "../../system/types/interface";
import {
  PortletLocationApi,
  PortletSettingApi,
  ErrorHandler,
} from "../../system/ApiService";
import { useUserState } from "../../system/context/UserContext";
import { useHistory } from "react-router-dom";
import TitleBox from "../../components/Common/TitleBox";

// css
import "../../../src/styles/Components/Main/Portlet.css";

// Syncfusion
import {
  DashboardLayoutComponent,
  PanelModel,
} from "@syncfusion/ej2-react-layouts";

// ui
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// 포틀릿
import NoticePortlet from "../Portlet/short/NoticePortlet";
import QnaPortlet from "../Portlet/short/QnaPortlet";
import WeatherPortlet from "../Portlet/short/WeatherPortlet";
import OfficeAttendancePortlet from "../Portlet/short/OfficeAttendancePortlet";
import BreakfastPortlet from "../Portlet/short/BreakfastPortlet";
import CheonanMenuPortlet from "../Portlet/short/CheonanMenuPortlet";
import { setEnvironmentData } from "worker_threads";
import { X } from "@phosphor-icons/react";
import { CloseButton, ModalTitle } from "../../styles/theme";
import {
  CustomButtonBlackSpace,
  CustomButtonGrey,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import AlertModal from "../Common/AlertModal";
import PaymentProgressPortlet from "../Portlet/PaymentProgressPortlet";
import PendingDocumentPortlet from "../Portlet/PendingDocumentPortlet";
import PaymentProgressStatusPortlet from "../Portlet/PaymentProgressStatusPortlet";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 700,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const _initGrabData = {
  target: null,
  position: null,
  move_up: [],
  move_down: [],
  updateList: [],
};

function Portlet() {
  const user = useUserState();
  const userId = user.employeeId;

  let history = useHistory();

  // 모달
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  function handleClose() {
    setOpen(false);
    refreshPortletLocation();
  }

  // 포틀릿 설정
  const [weatherPortlet, setWeatherPortlet] = useState(true);
  const [officeAttendancePortlet, setOfficeAttendancePortlet] = useState(true);
  const [cheonanMenuPortlet, setCheonanMenuPortlet] = useState(true);
  const [paymentProgressPortlet, setPaymentProgressPortlet] = useState(true);
  const [pendingDocumentPortlet, setPendingDocumentPortlet] = useState(true);
  const [paymentProgressStatusPortlet, setPaymentProgressStatusPortlet] =
    useState(true);

  const [portletSettingData, setPortletSettingData]: any = useState<
    PortletSetting[]
  >([]);
  function refreshPortlet() {
    PortletSettingApi.GetPortletSetting(user.employeeId)
      .then((res) => {
        setPortletSettingData(res.data);
        setWeatherPortlet(res.data.weatherPortlet);
        setOfficeAttendancePortlet(res.data.officeAttendancePortlet);
        setCheonanMenuPortlet(res.data.cheonanMenuPortlet);
        setPaymentProgressPortlet(res.data.paymentProgressPortlet);
        setPendingDocumentPortlet(res.data.pendingDocumentPortlet);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshPortlet();
  }, []);

  // 리턴값이 있는 반복문 x.map
  // 연산을 위한 반복문

  // 모달
  // 저장 버튼
  const savePortlet = () => {
    setOpen(false);

    if (portletSettingData) {
      const newData: PortletSetting = {
        portletId: userId,
        weatherPortlet: weatherPortlet,
        officeAttendancePortlet: officeAttendancePortlet,
        cheonanMenuPortlet: cheonanMenuPortlet,
        paymentProgressPortlet: paymentProgressPortlet,
        pendingDocumentPortlet: pendingDocumentPortlet,
        paymentProgressStatusPortlet: paymentProgressStatusPortlet,
      };
      PortletSettingApi.UpdatePortletSetting(newData)
        .then(() => {
          // alert("포틀릿 설정이 완료되었습니다.");
          refreshPortlet();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    } else {
      const newData: PortletSetting = {
        portletId: userId,
        weatherPortlet: weatherPortlet,
        officeAttendancePortlet: officeAttendancePortlet,
        cheonanMenuPortlet: cheonanMenuPortlet,
        pendingDocumentPortlet: pendingDocumentPortlet,
        paymentProgressPortlet: paymentProgressPortlet,
        paymentProgressStatusPortlet: paymentProgressStatusPortlet,
      };
      const newData2: PortletLocation = {
        portletId: user.employeeId,
        weatherPortlet: 0,
        officeAttendancePortlet: 1,
        cheonanMenuPortlet: 2,
        pendingDocumentPortlet: 3,
        paymentProgressPortlet: 4,
        paymentProgressStatusPortlet: 5,
      };
      PortletSettingApi.InsertPortletSetting(newData)
        .then(() => {
          refreshPortlet();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
      PortletLocationApi.InsertPortletLocation(newData2)
        .then(() => {
          refreshPortlet();
          refreshPortletLocation();
        })
        .catch((error) => {
          let msg = ErrorHandler(error);
          AlertModal("msg", msg);
        });
    }
  };

  const ChangePortlet1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeatherPortlet(event.target.checked);
  };
  const ChangePortlet2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOfficeAttendancePortlet(event.target.checked);
  };

  const ChangePortlet3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheonanMenuPortlet(event.target.checked);
  };
  const ChangePortlet4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentProgressPortlet(event.target.checked);
  };
  const ChangePortlet5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPendingDocumentPortlet(event.target.checked);
  };
  const ChangePortlet6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentProgressStatusPortlet(event.target.checked);
  };
  // 포틀릿 위치 설정

  const [portletLocationData, setPortletLocationData]: any = useState<
    PortletLocation[]
  >([]);
  const [portletLocationArray, setPortletLocationArray] = useState<
    PortletLocationArray[]
  >([]);
  const portletName = [
    "employeeId",
    "날씨 예보",
    "근태정보",
    "주간 아침 메뉴 (본사)",
    "오늘의 메뉴 (천안)",
    "상신함",
    "미결함",
    "결재 진행 현황",
  ];
  const portletClass = [
    "employeeId",
    <WeatherPortlet />,
    <OfficeAttendancePortlet />,
    <BreakfastPortlet />,
    <CheonanMenuPortlet />,
    <PaymentProgressPortlet />,
    <PendingDocumentPortlet />,
    <PaymentProgressStatusPortlet />,
  ];
  const portletNameList = [
    "날씨 예보",
    "근태정보",
    "주간 아침 메뉴 (본사)",
    "오늘의 메뉴 (천안)",
    "상신함",
    "미결함",
    "결재 진행 현황",
  ];

  function refreshPortletLocation() {
    PortletLocationApi.GetPortletLocation(user.employeeId)
      .then((res) => {
        setPortletLocationData(res.data);
        var entries = Object.entries(res.data);

        var test_arrays: any = [];
        for (var order = 0; order < entries.length - 1; order++) {
          for (
            var portletnumber = 0;
            portletnumber < entries.length;
            portletnumber++
          ) {
            if (entries[portletnumber][1] === order) {
              var test_dicts: any = {};
              test_dicts["class"] = entries[portletnumber][0];
              test_dicts["order"] = entries[portletnumber][1];
              test_dicts["Name"] = portletName[portletnumber];
              test_dicts["portletclass"] = portletClass[portletnumber];
              test_arrays.push(test_dicts);
            }
          }
        }
        setPortletLocationArray(test_arrays);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  }

  useEffect(() => {
    refreshPortletLocation();
  }, []);

  const [grab, setGrab]: any = useState(_initGrabData);
  const [isDrag, setIsDrag] = useState(false);

  React.useEffect(() => {}, [grab, portletLocationArray]);
  const _onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const _onDragStart = (e: any) => {
    setIsDrag(true);
    setGrab({
      ...grab,
      target: e.target,
      position: Number(e.target.dataset.position),
      updateList: [...portletLocationArray],
    });

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);

    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  };

  const _onDragEnd = (e: any) => {
    setIsDrag(false);
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
    setPortletLocationArray([...grab.updateList]);
    setGrab({
      target: null,
      move_up: [],
      move_down: [],
      updateList: [],
    });
    e.target.style.visibility = "visible";
  };

  const _onDragEnter = (e: any) => {
    let grabPosition = Number(grab.target.dataset.position);
    let listPosition = grab.position;
    let targetPosition = Number(e.target.dataset.position);

    let move_up = [...grab.move_up];
    let move_down = [...grab.move_down];

    let data = [...grab.updateList];
    data[listPosition] = data.splice(targetPosition, 1, data[listPosition])[0];

    if (grabPosition > targetPosition) {
      move_down.includes(targetPosition)
        ? move_down.pop()
        : move_down.push(targetPosition);
    } else if (grabPosition < targetPosition) {
      move_up.includes(targetPosition)
        ? move_up.pop()
        : move_up.push(targetPosition);
    } else {
      move_down = [];
      move_up = [];
    }

    setGrab({
      ...grab,
      move_up,
      move_down,
      updateList: data,
      position: targetPosition,
    });
  };

  const _onDragLeave = (e: any) => {
    if (e.target === grab.target) {
      e.target.style.visibility = "hidden";
    }
  };

  // // 버튼 클릭 시 portletdata가 들어있지 않다면 Insert, 들어있으면 Update
  const onSave = (args: any) => {
    var weatherLocation = 0;
    var officeAttendanceLocation = 1;
    var breakfastLocation = 2;
    var cheonanMenuLocation = 3;
    var paymentProgressLocation = 4;
    var pendingDocumentLocation = 5;
    var paymentProgressStatusLocation = 6;

    for (var i = 0; i < 7; i++) {
      for (var j = 0; j < 7; j++) {
        if (portletLocationArray[i].Name === portletNameList[j]) {
          if (portletLocationArray[i].Name === "날씨 예보") {
            weatherLocation = i;
          } else if (portletLocationArray[i].Name === "근태정보") {
            officeAttendanceLocation = i;
          } else if (portletLocationArray[i].Name === "주간 아침 메뉴 (본사)") {
            breakfastLocation = i;
          } else if (portletLocationArray[i].Name === "오늘의 메뉴 (천안)") {
            cheonanMenuLocation = i;
          } else if (portletLocationArray[i].Name === "상신함") {
            paymentProgressLocation = i;
          } else if (portletLocationArray[i].Name === "미결함") {
            pendingDocumentLocation = i;
          } else if (portletLocationArray[i].Name === "결재 진행 현황") {
            paymentProgressStatusLocation = i;
          }
        }
      }
    }

    const newData = {
      ...args.data,
      PortletId: user.employeeId,
      WeatherPortlet: weatherLocation,
      OfficeAttendancePortlet: officeAttendanceLocation,
      BreakfastPortlet: breakfastLocation,
      CheonanMenuPortlet: cheonanMenuLocation,
      PaymentProgressPortlet: paymentProgressLocation,
      PendingDocumentPortlet: pendingDocumentLocation,
      PaymentProgressStatusPortlet: paymentProgressStatusLocation,
    };

    PortletLocationApi.UpdatePortletLocation(newData)
      .then(() => {
        refreshPortlet();
        setOpen(false);
      })
      .catch((error) => {
        let msg = ErrorHandler(error);
        AlertModal("msg", msg);
      });
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // const error = [Notice, Qna].filter((v) => v).length != 2; // 나중에는 갯수 6개 제한

  return (
    <div>
      <div className="control-section" id="control">
        <div className="flex-container">
          <div className="flex-item">
            <NoticePortlet />
          </div>
          <div className="flex-item">
            <QnaPortlet />
          </div>
          {portletLocationArray.map((item: any, index: number) => (
            <li key={index} className="flex-itemList">
              {portletSettingData[item["class"]] ? (
                <div className="flex-item">{item.portletclass}</div>
              ) : (
                ""
              )}
            </li>
          ))}
        </div>
      </div>
      <div className="portlet_buttons" id="properties">
        <CustomButtonWhite onClick={handleOpen}>포틀릿 설정</CustomButtonWhite>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseButton>
              <X onClick={handleClose} size={40} />
            </CloseButton>
            <ModalTitle>
              <TitleBox title="포틀릿 관리" />
            </ModalTitle>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="inherit"
                  aria-label="inherit tabs example"
                >
                  <Tab
                    className="tabtitle"
                    label="포틀릿 설정"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tabtitle"
                    label="포틀릿 순서"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <div className="tabcomponent">
                {value === 0 ? (
                  <div>
                    <Typography variant="subtitle1" component="div">
                      원하시는 포틀릿을 선택해 주세요.
                    </Typography>
                    <div className="portletSettingList">
                      <Box sx={{ display: "flex" }}>
                        <FormControl
                          sx={{ m: 2 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={true}
                                  disabled
                                  name="Notice"
                                />
                              }
                              label="전사 공지"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox checked={true} disabled name="Qna" />
                              }
                              label="질의응답"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!weatherPortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet1}
                                  name="info_diligence"
                                />
                              }
                              label="현재 날씨"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!officeAttendancePortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet2}
                                  name="two"
                                />
                              }
                              label="근태정보"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!cheonanMenuPortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet3}
                                  name="four"
                                />
                              }
                              label="오늘의 메뉴 (천안)"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!paymentProgressPortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet4}
                                  name="five"
                                />
                              }
                              label="상신함"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!pendingDocumentPortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet5}
                                  name="six"
                                />
                              }
                              label="미결함"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!paymentProgressStatusPortlet}
                                  sx={{
                                    color: "#971B2F",
                                    "&.Mui-checked": { color: "#971B2F" },
                                  }}
                                  onChange={ChangePortlet6}
                                  name="six"
                                />
                              }
                              label="결재 진행 현황"
                            />
                          </FormGroup>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="tab_bt_wrap">
                      <CustomButtonGrey onClick={handleClose}>
                        취소
                      </CustomButtonGrey>
                      <CustomButtonBlackSpace onClick={savePortlet}>
                        저장
                      </CustomButtonBlackSpace>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {value === 1 ? (
                  <div>
                    <Typography variant="subtitle1" component="div">
                      ※ 전사 공지, 질의응답 포틀릿은 상단에 고정됩니다.
                    </Typography>
                    <div className="portletlist">
                      <div className="portletLocationList">
                        <div className="fixedPortlet">전사 공지</div>
                        <div className="fixedPortlet">질의응답</div>
                        <div
                          onDragOver={_onDragOver}
                          className="movePortletList"
                        >
                          {portletLocationArray
                            ? portletLocationArray.map((item: any, index) => (
                                <li
                                  key={index}
                                  className="movePortlet"
                                  draggable={true}
                                  data-position={index}
                                  onDragStart={_onDragStart}
                                  onDragEnd={_onDragEnd}
                                  onDragEnter={_onDragEnter}
                                  onDragLeave={_onDragLeave}
                                >
                                  {item.Name}
                                </li>
                              ))
                            : " "}
                          <div className="disabledPortlet"> </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab_bt_wrap">
                      <CustomButtonGrey onClick={handleClose}>
                        취소
                      </CustomButtonGrey>
                      <CustomButtonBlackSpace onClick={onSave}>
                        저장
                      </CustomButtonBlackSpace>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Portlet;
