import { Box, Grid, Modal, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  BoxSpacing,
  BoxTitle,
  CalendarBox,
  CommuteBox,
  FixedBox,
  FixedPortletMainBox,
  GridLeftBox,
  ScheduleEmptyBox,
  NoticeBox,
  RedTitleText,
  ScheduleBox,
  ScheduleSubBox,
  WorkingTimeAlign,
  WorkingTimeContent,
  WorkingTimeTitle,
  NoticeBoxTitle,
  NoticeBoxLayout,
  NoticeViewAll,
  OwTimeBox,
  GridRightBox,
} from "../../styles/Components/Main/MainPortal";
import { useUserState } from "../../system/context/UserContext";
import { useHistory } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Calendar, CalendarComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios";
import * as gregorian from "../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as numberingSystems from "../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import * as weekData from "../../../node_modules/cldr-data/supplemental/weekData.json";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { AttendanceInfo, DailyWorkReport } from "../../system/types/interface";
import {
  InitAttendanceInfo,
  InitDailyWorkReport,
} from "../../system/types/initObject";
import InfoIcon from "@mui/icons-material/Info";
import LongNoticePortlet from "../Portlet/long/LongNoticePortlet";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import OwTimePortlet from "./portlets/OwTimePortlet";
import {
  CustomButtonGreen,
  CustomButtonWhite,
} from "../../styles/Common/CommonComponent";
import { CloseButton, ModalTitle } from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import TitleBox from "../Common/TitleBox";
import AttendanceAlarmWithAwayTime from "./portlets/AttendanceAlarmWithAwayTime";
import AwayTimeInputForm from "./portlets/AwayTimeInputForm";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);

L10n.load({
  ko: {
    calendar: { today: "오늘" },
  },
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
}));

function FixedPortlet() {
  const user = useUserState();
  const history = useHistory();
  const [noticeClass, setNoticeClass] = useState(true);

  // 날짜
  const today = new Date();

  const todayYear = today.getFullYear();
  const tempTodayMonth = today.getMonth() + 1;
  const todayMonth =
    tempTodayMonth < 10 ? "0" + tempTodayMonth : tempTodayMonth;

  const tempTodayDate = today.getDate();
  const todayDate = tempTodayDate < 10 ? "0" + tempTodayDate : tempTodayDate;

  const [calendarDay, setCalendarDay] = useState(
    `${todayYear}-${todayMonth}-${todayDate}`
  );
  const [calendarMonth, setCalendarMonth] = useState(todayMonth);
  const [calendarDate, setCalendarDate] = useState(todayDate);
  let calendarInstance: Calendar | null = null;

  function selectedCalendar() {
    if (calendarInstance && calendarInstance.value) {
      var preMonth =
        calendarInstance.value.getMonth() + 1 < 10
          ? "0" + (calendarInstance.value.getMonth() + 1)
          : calendarInstance.value.getMonth() + 1;
      var preDate =
        calendarInstance.value.getDate() < 10
          ? "0" + calendarInstance.value.getDate()
          : calendarInstance.value.getDate();
      setCalendarDay(
        calendarInstance.value.getFullYear() + "-" + preMonth + "-" + preDate
      );
      setCalendarMonth(preMonth);
      setCalendarDate(preDate);
    }
  }

  // 세부출입기록 조회 모달
  const [alarmOpen, setAlarmOpen] = useState<boolean>(false);
  const handleAlarmOpen = () => {
    setAlarmOpen(true);
  };
  const handleAlarmClose = () => {
    setAlarmOpen(false);
  };
  // 이석시간입력 모달
  const [awayOpen, setAwayOpen] = useState<boolean>(false);
  const handleAwayOpen = () => {
    setAwayOpen(true);
  };
  const handleAwayClose = () => {
    setAwayOpen(false);
  };

  // 오늘의 출퇴근 현황 + 이석시간 + 주간 누적 초과근무시간
  const [report, setReport] = useState<DailyWorkReport>(InitDailyWorkReport);

  useEffect(() => {
    axios
      .get(
        `https://insamansa2.weareinbody.com/api/attendance/report/${user.gwMail}/${calendarDay}`
      )
      .then((res) => {
        setReport(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [calendarDay, user.gwMail, alarmOpen, awayOpen]);

  // 근태
  const [AttendanceInfoData, setAttendanceInfoData] =
    useState<AttendanceInfo>(InitAttendanceInfo);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://insamansa2.weareinbody.com/api/attendance/monthly/${user.employeeId}/${todayYear}/${todayMonth}`
      )
      .then((res) => {
        setAttendanceInfoData(res.data);
      });
  }, []);

  // todo
  const [todoData, setTodoData]: any = useState([]);

  useEffect(() => {
    let token = sessionStorage.getItem("token") || "";

    fetch(
      `https://graph.microsoft.com/v1.0/me/calendarview?$top=1000&$skip=0&startdatetime=${calendarDay}T00:00:00.000Z&enddatetime=${calendarDay}T23:59:59.999Z`,
      {
        headers: {
          Authorization: token,
          Prefer: 'outlook.timezone = "Korea Standard Time"',
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        let preData = res.value?.slice(0).sort(function (a: any, b: any) {
          return (
            new Date(a.start.dateTime).getTime() -
            new Date(b.start.dateTime).getTime()
          );
        });

        const newpreData = preData?.filter(function (element: any) {
          return element.start.dateTime.slice(0, 10) === calendarDay;
        });
        setTodoData(newpreData);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [calendarDay]);

  function moveToView() {
    if (noticeClass) {
      history.push("/notice/board/1");
    } else {
      history.push("/departmentnotice/board/1");
    }
  }

  return (
    <FixedPortletMainBox>
      <FixedBox>
        {/* 좌측 고정 그리드 - 캘린더, 일정, 출퇴근시간, 근태 */}
        <GridLeftBox>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              {/* 캘린더 */}
              <CalendarBox>
                <CalendarComponent
                  id="calendar"
                  onClick={selectedCalendar}
                  ref={(scope) => {
                    (calendarInstance as Calendar | null) = scope;
                  }}
                  locale="ko"
                />
              </CalendarBox>
            </Grid>
            <Grid item xs={6}>
              {/* 오늘의 일정 */}
              <ScheduleBox>
                {calendarDay === `${todayYear}-${todayMonth}-${todayDate}` ? (
                  <BoxTitle variant="h6">오늘의 일정</BoxTitle>
                ) : (
                  <BoxTitle variant="h6">
                    {calendarMonth}월 {calendarDate}일 일정
                  </BoxTitle>
                )}

                {/* 반복 */}
                {todoData?.length === 0 ? (
                  <ScheduleSubBox>
                    <ScheduleEmptyBox>등록된 일정이 없습니다.</ScheduleEmptyBox>
                  </ScheduleSubBox>
                ) : (
                  todoData?.map((todo: any) => {
                    return (
                      <div key={todo.id}>
                        <ScheduleSubBox>
                          <div>
                            {todo.start.dateTime.slice(0, 10)}{" "}
                            {todo.start.dateTime.slice(11, 16)} -{" "}
                            {todo.start.dateTime.slice(0, 10) ===
                            todo.end.dateTime.slice(0, 10)
                              ? null
                              : `${todo.end.dateTime.slice(0, 10)}`}
                            {todo.end.dateTime.slice(11, 16)}
                          </div>

                          <div>
                            {todo.location.displayName
                              ? `${todo.location.displayName}`
                              : null}
                          </div>
                          <WorkingTimeContent>
                            {todo.subject}
                          </WorkingTimeContent>
                        </ScheduleSubBox>
                      </div>
                    );
                  })
                )}
              </ScheduleBox>
            </Grid>
            <Grid item xs={6}>
              {/* 오늘 출퇴근 시간 */}
              <CommuteBox>
                <BoxTitle variant="h6">
                  {calendarDay === `${todayYear}-${todayMonth}-${todayDate}`
                    ? `오늘의 출퇴근 현황`
                    : `${calendarMonth}월 ${calendarDate}일 출퇴근 현황`}
                </BoxTitle>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>출근 시간</WorkingTimeTitle>
                  <WorkingTimeContent>
                    {report.secomStartTime
                      ? `${String(
                          new Date(report.secomStartTime).getHours()
                        ).padStart(2, "0")}시 ${String(
                          new Date(report.secomStartTime).getMinutes()
                        ).padStart(2, "0")}분`
                      : "-"}
                  </WorkingTimeContent>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>퇴근 시간</WorkingTimeTitle>
                  <WorkingTimeContent>
                    {report.secomEndTime
                      ? `${String(
                          new Date(report.secomEndTime).getHours()
                        ).padStart(2, "0")}시 ${String(
                          new Date(report.secomEndTime).getMinutes()
                        ).padStart(2, "0")}분`
                      : "-"}
                  </WorkingTimeContent>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>이석 시간</WorkingTimeTitle>
                  <WorkingTimeContent>{`${
                    isNaN(report.awayTime) ? 0 : report.awayTime
                  }분`}</WorkingTimeContent>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>
                    <CustomButtonWhite
                      style={{ width: "130px" }}
                      onClick={handleAlarmOpen}
                    >
                      세부 출입 기록
                    </CustomButtonWhite>
                  </WorkingTimeTitle>
                  <WorkingTimeContent>
                    <CustomButtonGreen
                      style={{ width: "180px" }}
                      onClick={handleAwayOpen}
                    >
                      {"이석(비업무) 시간 입력"}
                    </CustomButtonGreen>
                  </WorkingTimeContent>
                </WorkingTimeAlign>
              </CommuteBox>
            </Grid>
            <Grid item xs={6}>
              {/* 근태 정보 */}
              <CommuteBox>
                <BoxTitle variant="h6">
                  근태 정보{" "}
                  <HtmlTooltip
                    placement="right"
                    title={
                      <Fragment>
                        <Typography color="inherit">근태 정보 기준</Typography>

                        <b>{" 전체 연차 = 연차 + 월차"}</b>
                        <br />

                        <b>{" 잔여 연차 = 전체 연차 -  사용 연차"}</b>
                        <br />

                        {
                          " ※ 입사 2년차까지의 연차 계산은 입사일부터 현재까지를 기준으로 합니다."
                        }
                        <br />
                      </Fragment>
                    }
                  >
                    <InfoIcon color="disabled" />
                  </HtmlTooltip>
                </BoxTitle>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>전체 연차</WorkingTimeTitle>
                  <WorkingTimeContent>
                    {AttendanceInfoData.totalLeave > 0
                      ? `${AttendanceInfoData.totalLeave}`
                      : "-"}
                    일
                  </WorkingTimeContent>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>잔여 연차</WorkingTimeTitle>
                  <RedTitleText>
                    {AttendanceInfoData.totalLeave -
                      AttendanceInfoData.usedAnnualLeave >
                    0
                      ? `${
                          AttendanceInfoData.totalLeave -
                          AttendanceInfoData.usedAnnualLeave
                        }`
                      : "-"}
                    일
                  </RedTitleText>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>연간 지각</WorkingTimeTitle>
                  <WorkingTimeContent>
                    {AttendanceInfoData.yearlyLate -
                      AttendanceInfoData.yearlyLateExp >
                    0
                      ? `${
                          AttendanceInfoData.yearlyLate -
                          AttendanceInfoData.yearlyLateExp
                        }`
                      : "-"}
                    일
                  </WorkingTimeContent>
                </WorkingTimeAlign>
                <WorkingTimeAlign>
                  <WorkingTimeTitle>월간 야근</WorkingTimeTitle>
                  <WorkingTimeContent>
                    {AttendanceInfoData.monthlyNightShift
                      ? `${AttendanceInfoData.monthlyNightShift}`
                      : "-"}
                    일
                  </WorkingTimeContent>
                </WorkingTimeAlign>
              </CommuteBox>
            </Grid>
            <Grid item xs={12}>
              {/* 초과근무 시간 */}
              <OwTimeBox>
                <OwTimePortlet
                  searchDate={new Date(calendarDay)}
                  owTime={report.weeklyOwTime} // 주간초과근무시간에서 주간이석시간 합 빼기
                ></OwTimePortlet>
              </OwTimeBox>
            </Grid>
          </Grid>
        </GridLeftBox>
        {/* 우측 고정 그리드 - 공지사항, 초과근무시간 */}
        <GridRightBox>
          <BoxSpacing>
            {/* 공지사항 */}
            <NoticeBox>
              <NoticeBoxLayout>
                <NoticeBoxTitle variant="h6">공지사항</NoticeBoxTitle>
                <NoticeViewAll onClick={moveToView}>전체보기</NoticeViewAll>
              </NoticeBoxLayout>

              <LongNoticePortlet
                noticeClass={noticeClass}
                setNoticeClass={setNoticeClass}
              />
            </NoticeBox>
          </BoxSpacing>
        </GridRightBox>
      </FixedBox>
      {/* ########### 세부출입기록 & 이석시간 입력 모달 ############ */}
      {/* 세부출입기록 */}
      <Modal open={alarmOpen} onClose={handleAlarmClose}>
        <Box sx={styleAlarm}>
          <CloseButton>
            <X onClick={handleAlarmClose} size={40} />
          </CloseButton>
          <ModalTitle>
            <TitleBox title="세부 출입 기록" />
          </ModalTitle>
          {/* 출입기록 테이블 */}
          <AttendanceAlarmWithAwayTime
            workdate={calendarDay}
            handleAlarmClose={handleAlarmClose}
          ></AttendanceAlarmWithAwayTime>
        </Box>
      </Modal>
      {/* 이석시간 입력 */}
      <Modal open={awayOpen} onClose={handleAwayClose}>
        <Box sx={styleAwayTime}>
          <CloseButton>
            <X onClick={handleAwayClose} size={40} />
          </CloseButton>
          <ModalTitle>
            <TitleBox title="이석(비업무) 시간 입력" />
          </ModalTitle>
          {/* 출입기록 테이블 */}
          <AwayTimeInputForm
            calendarDay={calendarDay}
            handleAwayClose={handleAwayClose}
          ></AwayTimeInputForm>
        </Box>
      </Modal>
    </FixedPortletMainBox>
  );
}

// 세부 출입기록 모달 style
const styleAlarm = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 720,
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
};
// 이석시간 입력 모달 style
const styleAwayTime = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "auto",
  bgcolor: "#ffffff",
  border: "1px solid #E0E0E0",
  boxShadow: 24,
  px: 4,
  paddingBlock: "20px",
};

export default FixedPortlet;
